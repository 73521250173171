import type {Instruction} from '@backstage/instructions';
import type {TUnion} from '@sinclair/typebox';
import {useMachine} from '@xstate/react';
import {useObservableState, useSubscription} from 'observable-hooks';
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  type FC,
  type MutableRefObject,
  type PropsWithChildren,
} from 'react';
import {filter, map, mergeMap, Observable, partition, Subject} from 'rxjs';
import {
  createInstructionValidator,
  FLOW_IGNORE,
  isAboutMe,
  storage,
  type Broadcaster,
  type DeriveInstruction,
  type InstructionSchema,
  type ModuleIdentifiers,
} from '../../helpers';
import {Registry} from '../../registry';
import {JSONValue, SiteVariableLookup} from '../../types';
import {useManagedRef} from '../useManagedRef';
import {useModuleIdentifiers} from '../useModuleIdentifiers';
import {useTrackInstruction} from '../useTrackInstruction';
import {CollectingBehaviorSubject} from './CollectingBehaviorSubject';
import {GlobalInstructionHandlingProvider} from './GlobalInstructionHandlingProvider';
import {processInstructionFlows} from './process-instruction-flows';
import {createInstructionFetchMachine} from './show-instructions-machine';
import {BroadcastFunction} from './transformations/node.types';
import type {
  AppPage,
  FetchInstructionsFn,
  ShowFetchInstructionsFn,
} from './types';

export interface ShowInstructionsContextValue {
  /** Function to execute to get details about the current page */
  getCurrentPage: () => AppPage | undefined;
  /** Id of the show whose instructions are being handled. */
  showId: string;
  /** The domain name for the current site */
  domainName?: string;
  simpleBroadcast: BroadcastFunction;
  subject: Subject<Instruction[] | Error>;
}

/**
 * Explicit path used to indicate the page should be used for fallback (404)
 * content. If this ever changes check other places using this to make sure
 * validation related Regular Expressions are updated to match the new value.
 */
export const FALLBACK_PAGE_PATH = '/*';

const FALLBACK_APP_PAGE: AppPage | undefined =
  typeof document !== 'undefined'
    ? {
        pathname: FALLBACK_PAGE_PATH,
        structure: document.implementation.createDocument(null, 'Root', null),
      }
    : undefined;

export interface ShowInstructionsProviderProps {
  /** Origins in which the site may be embedded */
  allowedEmbedOrigins?: string[];
  /** URL to which instructions will be transmitted as analytics */
  analyticsEndpoint?: string;
  /** Token used to identify a specific guest anonymously */
  analyticsToken?: string;
  /** Contains structured page data for each path */
  appPages?: AppPage[];
  /**
   * Whether the context provider should automatically fetch instructions when
   * mounting. This option is intended to be used for tests, to avoid the "not
   * wrapped in act" warning
   * @default true
   */
  autoFetch?: boolean;
  /** Domain on which the `showId` was viewed */
  domainName?: string;
  /**
   * Function used to retrieve new instructions, performs no fetches if not
   * provided. This can be used to prevent retrieving instructions until the
   * `showId` can be confidently provided.
   */
  fetchInstructions?: FetchInstructionsFn;
  /**
   * If set to `true` will be treated as though the provider is loaded
   * regardless of internal state.
   */
  isLoaded?: boolean;
  /** Element at the root of the application */
  rootElement?: HTMLElement;
  /** Id of the show whose instructions are being handled. */
  showId: string;
  /** "dictionary" of SiteVariable keys to values */
  siteVariableLookup?: SiteVariableLookup;
}

/**
 * @private Exported for testing purposes only
 */
export const ShowInstructionsContext = createContext<
  ShowInstructionsContextValue | undefined
>(undefined);
ShowInstructionsContext.displayName = 'ShowInstructionsContext';

/**
 * `useContext` wrapper for `ShowInstructionsContext` - prevents misuse of the context and fails fast.
 */
const useShowInstructionsContext = (): ShowInstructionsContextValue => {
  const context = useContext(ShowInstructionsContext);
  if (context === undefined) {
    throw new Error(
      'useShowInstructionsContext must be used within a ShowInstructionsProvider'
    );
  }
  return context;
};

/**
 * The list of instruction types marked as "internal only" by the presence of
 * the `FLOW_IGNORE` property in the schema.
 */
const internalTopics: string[] = [];

/**
 * Context `Provider` to create and hold show instructions.
 */
export const ShowInstructionsProvider: FC<
  PropsWithChildren<ShowInstructionsProviderProps>
> = (props) => {
  const {
    allowedEmbedOrigins,
    analyticsEndpoint,
    analyticsToken,
    appPages = [],
    autoFetch = true,
    children,
    domainName,
    fetchInstructions,
    rootElement,
    showId,
    siteVariableLookup = {},
  } = props;
  const track = useTrackInstruction({
    analyticsEndpoint,
    analyticsToken,
    domainName,
    showId,
  });
  // Collects instructions which have been broadcast for delivery to modules
  const subjectRef = useRef(new CollectingBehaviorSubject<Instruction>());
  const appPagesRef = useManagedRef(appPages);
  const value = useMemo(
    () => ({showId, domainName, subject: subjectRef.current}),
    [showId, domainName]
  );
  const earlyInstructions = useRef<Instruction[]>([]);
  const isLoaded = useRef(props.isLoaded ?? false);
  // Set up broadcasting which will track every instruction at "broadcast"
  // passing along the `source` information as part of the tracking
  const simpleBroadcast: BroadcastFunction = useMemo(() => {
    return (instruction, source = 'internal') => {
      track(instruction, source);
      if (isLoaded.current || internalTopics.includes(instruction.type)) {
        subjectRef.current.next([instruction]);
      } else {
        earlyInstructions.current.push(instruction);
      }
    };
  }, [track]);
  // If debugging features are on then add a `broadcast` function to the window
  // This gives a way for developers to test out broadcasting an arbitrary
  // instruction in the browser
  useEffect(() => {
    if (
      storage.getItem('debug:broadcast') === 'true' &&
      typeof window !== 'undefined' &&
      window.location.hostname === 'localhost'
    ) {
      // @ts-expect-error adding to global
      window.broadcast = simpleBroadcast;
      return () => {
        // @ts-expect-error deleting from global
        delete window.broadcast;
      };
    }
  }, [simpleBroadcast]);
  // If instruction logging is turned on this subscription will log everything
  // coming through `subject`.
  useSubscription(value.subject, (instructions) => {
    // someone turned on logging
    if (storage.getItem('logInstructions') === 'true') {
      console.info('ℹ️', JSON.stringify(instructions));
    }
  });
  // This uses knowledge of another internal component to get the last navigated
  // pathname
  const appPagePath$ = useMemo(
    () =>
      value.subject.pipe(
        filter((value): value is Instruction[] => Array.isArray(value)),
        mergeMap((instructions) => instructions),
        filter((instruction) => instruction.type === 'Router:on-navigate'),
        map((instruction) => instruction.meta.currentPath)
      ),
    [value.subject]
  );
  const appPagePath = useObservableState(appPagePath$, FALLBACK_PAGE_PATH);
  // When the path changes reset collected instructions so they do not replay
  // on the new page
  useSubscription(appPagePath$, {
    next() {
      value.subject.reset();
    },
  });
  const getCurrentPage = useCallback(
    () =>
      extractPage(appPagesRef.current, appPagePath) ??
      extractPage(appPagesRef.current, FALLBACK_PAGE_PATH) ??
      FALLBACK_APP_PAGE,
    [appPagePath, appPagesRef]
  );
  // Create the subscription to process flows
  useSubscription(value.subject, (instructions) => {
    if (Array.isArray(instructions)) {
      processInstructionFlows({
        broadcast: simpleBroadcast,
        getCurrentPage,
        instructions,
        showId,
        domainName,
        siteVariableLookup,
      });
    }
  });

  // These are refs so that `showInstructionsMachine` doesn't get recreated
  // when either of these change. The value of `getInstructionsRef` will be
  // picked up the next time the state machine fetches.
  const showIdRef = useManagedRef(showId);
  const getInstructionsRef = useManagedRef<ShowFetchInstructionsFn>((since) =>
    typeof fetchInstructions === 'function'
      ? fetchInstructions(showIdRef.current, since)
      : Promise.resolve({})
  );
  // Create the instructions polling state machine
  const showInstructionsMachine = useMemo(
    () =>
      createInstructionFetchMachine({
        getInstructions: getInstructionsRef,
        subject: value.subject,
        track,
      }),
    // If any of these dependencies are not stable `showInstructionsMachine`
    // will get re-created which will cause this to break; these dependencies
    // must not change across renders.
    [getInstructionsRef, track, value.subject]
  );
  const [state, dispatch] = useMachine(() => showInstructionsMachine);
  // Setup the instruction fetching function only if in the `INITIALIZING`
  // state, moving to the `WAITING` state to kick off the first fetch
  useEffect(() => {
    if (
      state.matches('INITIALIZING') &&
      typeof fetchInstructions === 'function'
    ) {
      dispatch({type: 'INIT'});
    }
  }, [dispatch, fetchInstructions, showIdRef, state]);
  // Only request instructions if `fetchInstructions` is provided in props,
  // waits until `getInstructions` exists before fetching first batch
  useEffect(() => {
    const currentPage = getCurrentPage();
    const isDone = state.matches('DONE') || state.matches('LISTENING');
    if (state.matches('WAITING') && autoFetch) {
      dispatch({type: 'FETCH'});
    } else if (currentPage && !isLoaded.current && isDone) {
      // The state machine moves to `DONE` (or `ERROR`) after the first fetch is
      // complete and so indicate the initial fetch of instructions has been
      // completed
      onLoad(isLoaded, earlyInstructions, value.subject);
    }
  }, [autoFetch, getCurrentPage, dispatch, state, value.subject]);
  // When forced into an `isLoaded` state send any collected instructions
  useEffect(() => {
    if (props.isLoaded) {
      onLoad(isLoaded, earlyInstructions, value.subject);
    }
  }, [props.isLoaded, value.subject]);

  const contextValue = useMemo(() => {
    const result: ShowInstructionsContextValue = {
      getCurrentPage,
      simpleBroadcast,
      ...value,
    };
    return result;
  }, [getCurrentPage, simpleBroadcast, value]);
  return (
    <ShowInstructionsContext.Provider value={contextValue}>
      <GlobalInstructionHandlingProvider
        allowedEmbedOrigins={allowedEmbedOrigins}
        domainName={domainName}
        rootElement={rootElement}
      >
        {children}
      </GlobalInstructionHandlingProvider>
    </ShowInstructionsContext.Provider>
  );
};

/**
 * For a given list of `pages` extract the one where `pathname` matches the
 * given `path`.
 */
const extractPage = (pages: AppPage[], path?: JSONValue): AppPage | undefined =>
  pages.find((page) => page.pathname === path);

/**
 * Set "loaded" state to `true` and push any collected instructions into
 * `subject` before clearing them.
 * @param isLoaded `Ref` for the "loaded" state
 * @param earlyInstructions `Ref` for the collected instructions
 * @param subject into which `earlyInstructions` will be pushed
 */
function onLoad(
  isLoaded: MutableRefObject<boolean>,
  earlyInstructions: MutableRefObject<Instruction[]>,
  subject: Subject<Error | Instruction[]>
): void {
  isLoaded.current = true;
  // Send any instructions that came in before first batch was loaded
  subject.next(earlyInstructions.current);
  // Clear out the early instructions; just in case
  earlyInstructions.current = [];
}

/**
 * Provides the `showId` for which instructions are currently being broadcast
 * and received.
 * @returns the `showId` of the current show instructions.
 */
export const useShowId = (): string => {
  const {showId} = useShowInstructionsContext();
  return showId;
};

/**
 * Provides the `domainName` for which instructions are currently being broadcast
 * and received, as stored in the `ShowInstructionsProvider`.
 * @returns the `domainName` of the current show instructions.
 */
export const useDomainName = (): string | undefined => {
  const {domainName} = useShowInstructionsContext();
  return domainName || undefined;
};

/**
 * Gives access to instruction messages for context's `showId` for instructions
 * which match the given `schema`.
 *
 * **WARN** Do not use this unless there are no `ModuleIdentifiers` for your
 * component.
 *
 * @param schema of the instructions to be broadcast and received
 * @returns object with show instructions observable and a function to
 * broadcast a new instruction.
 * @private exported for internal usage, using this API is considered undefined
 * behavior.
 */
export function useShowInstructions<
  InstructionTypes extends InstructionSchema[],
>(schema: TUnion<InstructionTypes>): ShowInstructions<InstructionTypes>;
/**
 * Gives access to instruction messages for context's `showId` for instructions
 * which match the given `schema`.
 * @param schema of the instructions to be broadcast and received
 * @param identifiers of the module broadcasting and receiving; if provided the
 * resulting `broadcast` function will always include it in the broadcasts.
 * @returns object with show instructions observable and a function to
 * broadcast a new instruction.
 */
export function useShowInstructions<
  InstructionTypes extends InstructionSchema[],
>(
  schema: TUnion<InstructionTypes>,
  identifiers: ModuleIdentifiers
): ShowInstructions<InstructionTypes>;

export function useShowInstructions<
  InstructionTypes extends InstructionSchema[],
>(
  schema: TUnion<InstructionTypes>,
  identifiers?: ModuleIdentifiers
): ShowInstructions<InstructionTypes> {
  type ValidatedInstruction = DeriveInstruction<InstructionTypes>;
  const isValidatedInstruction = useMemo(
    () => createInstructionValidator(schema),
    [schema]
  );
  const {getCurrentPage, simpleBroadcast, subject} =
    useShowInstructionsContext();
  const moduleIdentifiers = useModuleIdentifiers(identifiers);

  const about = useMemo(
    () => (moduleIdentifiers ? `#${moduleIdentifiers.id}` : null),
    [moduleIdentifiers]
  );

  // The `broadcast` function always adds `about` to the instruction, if given
  const broadcast: Broadcaster<InstructionTypes> = useCallback(
    (instruction) => {
      if (isValidatedInstruction(instruction)) {
        const instructionWithAbout = {
          type: instruction.type,
          meta: {
            ...instruction.meta,
            ...(typeof about === 'string' ? {about} : undefined),
          },
        };
        simpleBroadcast(instructionWithAbout, moduleIdentifiers);
      } else {
        console.warn({
          tag: 'useShowInstructions',
          msg: 'Invalid instruction',
          instruction,
        });
      }
    },
    [about, isValidatedInstruction, moduleIdentifiers, simpleBroadcast]
  );
  const [errors, instructions] = useMemo(() => {
    // Flatten the instructions so receivers do not get them in batches
    const [errors$, instructions$] = partition(
      subject,
      (next): next is Error => next instanceof Error
    );
    const observable: Observable<ValidatedInstruction> = instructions$.pipe(
      mergeMap((value) => {
        if (Array.isArray(value)) {
          return value;
        } else {
          return [value];
        }
      }),
      filter(isValidatedInstruction)
    );
    return [errors$, observable];
  }, [isValidatedInstruction, subject]);
  const filteredInstructions = useMemo(() => {
    const currentPage = getCurrentPage();
    const structure = currentPage?.structure;
    if (structure && about) {
      return instructions.pipe(
        filter<ValidatedInstruction>((inst) => {
          return isAboutMe(structure, inst?.meta?.about, about);
        })
      );
    } else {
      return instructions;
    }
  }, [about, getCurrentPage, instructions]);
  useSubscription(errors, (error) =>
    console.error({tag: 'InstructionError', error})
  );
  return {observable: filteredInstructions, broadcast};
}

export interface ShowInstructions<Schema extends InstructionSchema[]> {
  observable: Observable<DeriveInstruction<Schema>>;
  broadcast: Broadcaster<Schema>;
}

/**
 * When a Module is registered find any internal instructions and add them to
 * the `internalTopics` list.
 */
Registry.on('register', (c) => {
  const instructionSchemas = c.instructions?.anyOf ?? [];
  for (const schema of instructionSchemas) {
    if (FLOW_IGNORE in schema && schema[FLOW_IGNORE] === true) {
      internalTopics.push(schema.properties.topic.const);
    }
  }
});
